<template>
  <router-link :to="agent.web_url" v-if="agent" style="text-decoration: none">
    <div class="card--project card--agent">
      <div class="card-body">
        <div class="wrapper--info">
          <div class="d-flex justify-center">
            <img :src="agent.photo_profile" v-if="agent.photo_profile" alt="" class="agent--img" />
          </div>
          <div class="w-100 text-center mt-2">
            <div class="agent--name">
              {{ agent.full_name }}
            </div>
            <div class="agent--phone" v-if="agent.phone">
              <i class="ion-android-call mr-2"></i>
              {{ agent.phone }}
            </div>
            <div class="agent--email">
              {{ agent.email }}
            </div>
            <div>
              <span class="agent--job">
                {{ agent.job_title }}
              </span>
              <span v-if="agent.job_title && agent.company_name"> - </span>
              <span class="agent--company">
                {{ agent.company_name }}
              </span>
            </div>
            <div class="agent--reg-no">{{ $t('general.agentRegNo') }} {{ agent.agent_reg_no }}</div>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    agent: {
      type: Object,
      required: true,
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/utils/variables';
.card-body {
  padding: 1rem;
}
.card--agent {
  min-height: 320px;
  @media #{$phones} {
    min-height: 300px;
  }
}
.agent--img {
  width: 120px;
  height: 120px;
  object-fit: contain;
  border: solid 1px $color-gray-1;
  border-radius: 50%;
  @media #{$phones} {
    width: 80px;
    height: 80px;
  }
}
.agent--name {
  font-family: Poppins-Bold;
  font-size: $font-xl;
  color: $color-text-black;
  @media #{$phones} {
    font-size: $font-md;
  }
}
.agent--phone {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-beliruma-1;
  @media #{$phones} {
    font-size: $font-sm;
  }
}
.agent--email {
  color: $color-text-black;
  @media #{$phones} {
    font-size: $font-sm;
  }
}
.agent--job {
  font-size: $font-sm;
  color: $color-text-black;
  @media #{$phones} {
    font-size: $font-xs;
  }
}
.agent--company {
  font-size: $font-sm;
  color: $color-text-black;
  @media #{$phones} {
    font-size: $font-xs;
  }
}
.agent--reg-no {
  color: $color-text-black;
  margin-top: 24px;
  @media #{$phones} {
    font-size: $font-sm;
    margin-top: 16px;
  }
}
</style>
